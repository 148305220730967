<template>
  <Dropdown v-model="$i18n.locale" :options="arr" class="app-locales as-link dark">
    <template v-slot:renderValue="{opt, title}">
      <a v-if="title" class="app-locales__title">{{ opt.key }}</a>
      <a
        v-else="opt.key != $i18n.locale"
        :href="opt.value.url"
        class="app-locales__value"
      >{{ opt.key }}</a>
    </template>
  </Dropdown>
</template>

<script>
  import { Dropdown }     from "auth_app_vue/lib/elements";

  export default {
    name: "Locales",
    components: {
      Dropdown
    },
    props: {
      arr: !Array
    },
  }
</script>
